<template>
  <div>
    <van-popup v-model="lushow" :close-on-click-overlay="false">
      <div class="boss">
        <div class="content">
          <div class="header">
            <img src="../../../assets/img/lu.png" alt="" />
          </div>
          <div class="videobox">
            <video class="video" autoplay controls muted>
              <source :src="url" type="video/mp4" />
            </video>
          </div>
          <div class="close" @click="close">
            <img src="../../../assets/img/close.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    lushow: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("closeLu", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boss {
  width: 10rem;
  height: 8rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 9rem;
    height: 7rem;
    background: #fbe5ba;
    border-radius: 0.2rem;
    overflow: hidden;
    .header {
      height: 1.3rem;
      width: 100%;
      background: #d79f6d;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 0.75rem;
      }
    }
  }
  .videobox {
    width: 100%;
    height: 5.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box,
  .video {
    width: 8.3rem;
    height: 5.2rem;
  }
  .close {
    position: absolute;
    top: 0.16rem;
    right: 0.23rem;
    img {
      width: 1rem;
      height: auto;
    }
  }
}
.van-dialog {
  width: 6rem;
  height: 4rem;
  /deep/ .van-dialog__message--has-title {
    padding-top: 0.5rem;
  }
}
</style>
