<template>
  <div>
    <div class="content" ref="homeUl">
      <van-nav-bar :fixed="true">
        <template #left>
          <img src="../../assets/img/close.png" class="navImg" @click="close" />
        </template>
      </van-nav-bar>
      <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <my-scroll :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore">
            <div
              class="div-content"
              v-for="item in feedList"
              :key="item[0].date"
            >
              <div>
                <p class="time">{{ item[0].date }}</p>
                <div class="info" v-for="dd in item" :key="dd.id">
                  <div class="info_left">
                    <p class="info_time">{{ dd.time }}</p>
                    <div class="info_text" v-for="val in dd.feed" :key="val.id">
                      <p
                        v-if="
                          val.cate_id != 2 &&
                          val.cate_id != 1 &&
                          val.cate_id != 3
                        "
                      >
                        {{ val.name + '：' + val.weight + '克' }}
                      </p>
                      <p v-if="val.cate_id == 1">
                        {{ val.name + '：' + val.weight + '份' }}
                      </p>
                      <p v-if="val.cate_id == 2">
                        {{ val.name }}
                      </p>
                      <p v-if="val.cate_id == 3">
                        {{ val.name }}
                      </p>
                    </div>
                    <!-- <p class="info_text">普通水*1</p> -->
                  </div>
                  <div class="info_right" v-if="dd.video_url">
                    <p @click="lookLu(dd.video_url)">喂养视频</p>
                  </div>
                </div>
              </div>
            </div>
          </my-scroll>
        </van-pull-refresh>
      </div>
    </div>
    <luxiang
      :lushow="lushow"
      v-if="lushow"
      :url="video_url"
      @closeLu="closeLu"
    ></luxiang>
  </div>
</template>

<script>
import { record } from '../../common/api'
import luxiang from './components/luxiang.vue'
import myScroll from './components/myScroll.vue'
import Utils from '../../util/index'
export default {
  components: {
    myScroll,
    luxiang,
  },
  data() {
    return {
      feedList: [],
      page: 1,
      days: 5,
      enableLoadMore: true,
      none: false,
      isLoading: false,
      lushow: false,
      video_url: '',
    }
  },
  created() {
    this.recordJl()
  },
  mounted() {},
  methods: {
    close() {
      this.$router.go(-1)
      Utils.$emit('getToken')
    },
    // 关闭录屏
    closeLu(val) {
      this.lushow = val
    },
    lookLu(url) {
      this.video_url = url
      this.lushow = true
    },
    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: this.page,
        days: this.days,
      }
      let res = await record(data)
      if (res.data.code == 200) {
        if (this.page == 1) {
          this.feedList = res.data.data
        } else {
          this.feedList = [...this.feedList, ...res.data.data]
        }
      }
      if (res.data.code == '4004') {
        this.enableLoadMore = false
      }
    },
    // 上拉加载
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) return
        this.page = this.page + 1
        this.recordJl()
        done()
      }, 200)
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
        this.page = 1
        this.recordJl()
        this.enableLoadMore = true
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.van-nav-bar {
  width: 100%;
  height: 1.4rem;
  background: #d79f6d;
  /deep/ .van-nav-bar__content {
    height: 100%;
    font-size: 0.5rem;
  }
  /deep/ .van-nav-bar__title {
    font-size: 0.5rem;
    font-weight: 700;
    color: #fff;
    height: 1.4rem;
    line-height: 1.4rem;
    -webkit-text-stroke: 1px #945614;
  }
}
.navImg {
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 0.3rem;
}
.div-content:last-child {
  padding-bottom: 0.4rem;
}
.content {
  min-height: 100vh;
  width: 100vw;
  // padding-top: 1.41rem;
  background: #fbe5ba;
  display: flex;
  flex-direction: column;
  align-items: center;
  .time {
    width: 100%;
    font-size: 0.6rem;
    font-weight: 600;
    color: #945614;
    padding-top: 0.3rem;
  }

  .info {
    width: 9rem;
    // height: 2rem;
    background: #000;
    margin-top: 0.2rem;
    background: rgba(#945614, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.2rem;
    .info_left {
      width: 70%;
      height: 100%;
      .info_time {
        font-size: 0.35rem;
        color: #945614;
        padding-left: 0.3rem;
        padding-top: 0.15rem;
      }
      .info_text {
        font-size: 0.4rem;
        color: #945614;
        padding-left: 0.3rem;
        padding-top: 0.1rem;
        font-weight: 600;
      }
      .info_text:last-child {
        padding-bottom: 0.2rem;
      }
    }
    .info_right {
      p {
        padding: 0.1rem 0.34rem;
        background: #fbe5ba;
        margin-right: 0.3rem;
        border-radius: 4rem;
        color: #945614;
        border: 1px solid #945614;
      }
    }
  }
}
.van-pull-refresh {
  overflow: visible;
  /deep/ .van-pull-refresh__track {
    margin-top: 1.4rem;
  }
}
</style>
